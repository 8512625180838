.card {
  width: 100%;
  border: 1px solid rgba(#fff, 0.2);
  border-radius: 8px;
  padding: 16px 16px 72px;
  position: relative;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    span {
      color: #02bc7d;
    }
  }

  &__image {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
    object-fit: contain;
    pointer-events: none;
  }

  &__service {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &-img {
      width: 20px;
      margin-right: 10px;
    }
  }

  &__bottom {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 16px;
    display: flex;
    gap: 10px;
  }

  &__button {
    &-wrapper {
      width: 100%;

      &:hover {
        .card__button-hint {
          opacity: 1;
          transform: translate(-50%, 0);
        }
      }
    }

    &-hint {
      position: absolute;
      z-index: 10;
      font-size: 14px;
      left: 50%;
      transform: translate(-60%, -50%);
      bottom: -65px;
      width: 80%;
      background-color: #02bc7d;
      padding: 6px;
      border-radius: 8px;
      opacity: 0;
      transition: opacity .3s, transform .3s;
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;

        border-bottom: 8px solid #02bc7d;
      }
    }
  }
}
