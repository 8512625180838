.app {
  padding: 15px 0 60px;
  background-repeat: no-repeat;
  background-size: auto;
}

.bg {
  pointer-events: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(3px);
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 2;
  }
  &__img {
    width: 100%;
    height: 100%;
    @media (max-width: 1200px) {
      object-fit: cover;
      object-position: right;
    }
  }
}

.header {
  margin-bottom: 16px;
  position: relative;
  z-index: 2;

  &__inner {
    display: flex;
    align-items: center;
    img {
      margin-right: 16px;
    }
  }
}

.title {
  text-align: center;
  margin-bottom: 16px;
  position: relative;
  z-index: 2;
}

.text {
  text-align: center;
  max-width: 80%;
  margin: 0 auto 20px;
  position: relative;
  z-index: 2;
  @media (max-width: 800px) {
    max-width: 100%;
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
}
