* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #0a0f16;
  background-color: #202020;
  color: #fff;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 15px;
}
