.button {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-radius: 100px;
  color: #fff;
  background-color: #1a86f2;
  padding: 13px;
  transition: background-color 0.3s;
  cursor: pointer;
  border: none;
  width: 100%;

  &:hover {
    background-color: darken(#1a86f2, 5%);
  }
}
